const loadingIcons = document.querySelectorAll('.p-loading__icon');
const loading = document.querySelector('.p-loading');

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(function() {
        loadingIcons.forEach(function(loadingIcon) {
            loadingIcon.classList.add('is-active');
        });
    }, 500);
    setTimeout(function() {
        loading.classList.remove('is-active');
    }, 4000);
});

// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// 日付表示
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// 今日の日付を取得できるnew Dateを格納
const today = new Date();

// 年・月・日・曜日を取得
const month = today.getMonth() + 1;
const date = today.getDate();
const day = today.getDay();
const weekday = ["SUN","MON","TUE","WED","THU","FRI","SAT"];

// console.log(`${month}.${date}`);
// console.log(weekday[today.getDay()]);

document.querySelector('.p-fv__date .date').innerHTML += `${month}.${date}`;
document.querySelector('.p-fv__date .day').innerHTML += weekday[today.getDay()];


// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// ハンバーガーメニュー
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
document.addEventListener('DOMContentLoaded', function() {
    function addActive() {
        document.querySelector('.p-hamburger').classList.toggle('is-active')
        document.querySelector('.p-hamburger__line1').classList.toggle('is-active')
        document.querySelector('.p-hamburger__line2').classList.toggle('is-active')
        document.querySelector('.p-hamburger__line3').classList.toggle('is-active')
        document.querySelector('.p-hamburger__nav').classList.toggle('is-active')
        document.querySelector('body').classList.toggle('fixed');
    }
    document.querySelector('.p-hamburger').addEventListener('click', function() {
        addActive();
    });
})

const hamEls = document.querySelectorAll('.p-hamburger__close');
hamEls.forEach(function(hamEl) {
    hamEl.addEventListener('click', function() {
        document.querySelector('.p-hamburger').classList.remove('is-active')
        document.querySelector('.p-hamburger__line1').classList.remove('is-active')
        document.querySelector('.p-hamburger__line2').classList.remove('is-active')
        document.querySelector('.p-hamburger__line3').classList.remove('is-active')
        document.querySelector('.p-hamburger__nav').classList.remove('is-active')
        document.querySelector('body').classList.remove('fixed');
    }) 
});

// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
// TOPに戻るボタン
// ＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝
let scroll = new SmoothScroll('a[href*="#"]', {
    speedAsDuration: true,
    speed: 1000,
    easing: 'easeInOutQuint'
});  

gsap.to('.p-fv__car', {
    x: -100,
    scrollTrigger: {
      trigger: '.p-fv',
      start: 'top' ,
      end: 'bottom', 
      scrub: true, 
    }
});
gsap.to('.p-footer__car', {
    x: 0,
    scrollTrigger: {
      trigger: '.p-footer__social',
      start: 'center 90%' ,
      end: 'center 60%', 
      scrub: true, 
    }
});